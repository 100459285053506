export const books = [
    {book_id: 1, name: "Genèse", shortname: "Gen", nb_chapters: 50},
    {book_id: 2, name: "Exode", shortname: "Ex", nb_chapters: 40},
    {book_id: 3, name: "Lévitique", shortname: "Lev", nb_chapters: 27},
    {book_id: 4, name: "Nombres", shortname: "Num", nb_chapters: 36},
    {book_id: 5, name: "Deutéronome", shortname: "Deut", nb_chapters: 34},
    {book_id: 6, name: "Josué", shortname: "Josh", nb_chapters: 24},
    {book_id: 7, name: "Juges", shortname: "Judg", nb_chapters: 21},
    {book_id: 8, name: "Ruth", shortname: "Ru", nb_chapters: 4},
    {book_id: 9, name: "1 Samuel", shortname: "1_Sam", nb_chapters: 31},
    {book_id: 10, name: "2 Samuel", shortname: "2_Sam", nb_chapters: 24},
    {book_id: 11, name: "1 Rois", shortname: "1_Ki", nb_chapters: 22},
    {book_id: 12, name: "2 Rois", shortname: "2_Ki", nb_chapters: 25},
    {book_id: 13, name: "1 Chroniques", shortname: "1_Chron", nb_chapters: 29},
    {book_id: 14, name: "2 Chroniques", shortname: "2_Chron", nb_chapters: 36},
    {book_id: 15, name: "Esdras", shortname: "Ezra", nb_chapters: 10},
    {book_id: 16, name: "Néhémie", shortname: "Neh", nb_chapters: 13},
    {book_id: 17, name: "Esther", shortname: "Esth", nb_chapters: 10},
    {book_id: 18, name: "Job", shortname: "Job", nb_chapters: 42},
    {book_id: 19, name: "Psaumes", shortname: "Ps", nb_chapters: 150},
    {book_id: 20, name: "Proverbes", shortname: "Prov", nb_chapters: 31},
    {book_id: 21, name: "Ecclésiaste", shortname: "Ecc", nb_chapters: 12},
    {book_id: 22, name: "Cantique des Cantiques", shortname: "SOS", nb_chapters: 8},
    {book_id: 23, name: "Esaïe", shortname: "Isa", nb_chapters: 66},
    {book_id: 24, name: "Jérémie", shortname: "Jer", nb_chapters: 52},
    {book_id: 25, name: "Lamentations", shortname: "Lam", nb_chapters: 5},
    {book_id: 26, name: "Ezéchiel", shortname: "Eze", nb_chapters: 48},
    {book_id: 27, name: "Daniel", shortname: "Dan", nb_chapters: 12},
    {book_id: 28, name: "Osée", shortname: "Hos", nb_chapters: 14},
    {book_id: 29, name: "Joël", shortname: "Joel", nb_chapters: 3},
    {book_id: 30, name: "Amos", shortname: "Amos", nb_chapters: 9},
    {book_id: 31, name: "Abdias", shortname: "Obad", nb_chapters: 1},
    {book_id: 32, name: "Jonas", shortname: "Jon", nb_chapters: 4},
    {book_id: 33, name: "Michée", shortname: "Micah", nb_chapters: 7},
    {book_id: 34, name: "Nahum", shortname: "Nah", nb_chapters: 3},
    {book_id: 35, name: "Habacuc", shortname: "Hab", nb_chapters: 3},
    {book_id: 36, name: "Sophonie", shortname: "Zeph", nb_chapters: 3},
    {book_id: 37, name: "Aggée", shortname: "Hag", nb_chapters: 2},
    {book_id: 38, name: "Zacharie", shortname: "Zech", nb_chapters: 14},
    {book_id: 39, name: "Malachie", shortname: "Mal", nb_chapters: 4},
    {book_id: 40, name: "Matthieu", shortname: "Matt", nb_chapters: 28},
    {book_id: 41, name: "Marc", shortname: "Mark", nb_chapters: 16},
    {book_id: 42, name: "Luc", shortname: "Luke", nb_chapters: 24},
    {book_id: 43, name: "Jean", shortname: "John", nb_chapters: 21},
    {book_id: 44, name: "Actes des Apôtres", shortname: "Acts", nb_chapters: 28},
    {book_id: 45, name: "Romains", shortname: "Rom", nb_chapters: 16},
    {book_id: 46, name: "1 Corinthiens", shortname: "1_Cor", nb_chapters: 16},
    {book_id: 47, name: "2 Corinthiens", shortname: "2_Cor", nb_chapters: 13},
    {book_id: 48, name: "Galates", shortname: "Gal", nb_chapters: 6},
    {book_id: 49, name: "Ephésiens", shortname: "Eph", nb_chapters: 6},
    {book_id: 50, name: "Philippiens", shortname: "Phil", nb_chapters: 4},
    {book_id: 51, name: "Colossiens", shortname: "Col", nb_chapters: 4},
    {book_id: 52, name: "1 Thessaloniciens", shortname: "1_Thess", nb_chapters: 5},
    {book_id: 53, name: "2 Thessaloniciens", shortname: "2_Thess", nb_chapters: 3},
    {book_id: 54, name: "1 Timothée", shortname: "1_Tim", nb_chapters: 6},
    {book_id: 55, name: "2 Timothée", shortname: "2_Tim", nb_chapters: 4},
    {book_id: 56, name: "Tite", shortname: "Titus", nb_chapters: 3},
    {book_id: 57, name: "Philémon", shortname: "Phm", nb_chapters: 1},
    {book_id: 58, name: "Hébreux", shortname: "Heb", nb_chapters: 13},
    {book_id: 59, name: "Jacques", shortname: "Jas", nb_chapters: 5},
    {book_id: 60, name: "1 Pierre", shortname: "1_Pet", nb_chapters: 5},
    {book_id: 61, name: "2 Pierre", shortname: "2_Pet", nb_chapters: 3},
    {book_id: 62, name: "1 Jean", shortname: "1_John", nb_chapters: 5},
    {book_id: 63, name: "2 Jean", shortname: "2_John", nb_chapters: 1},
    {book_id: 64, name: "3 Jean", shortname: "3_John", nb_chapters: 1},
    {book_id: 65, name: "Jude", shortname: "Jude", nb_chapters: 1},
    {book_id: 66, name: "Apocalypse", shortname: "Rev", nb_chapters: 22}
]