import React from 'react';

// Styles
import './styles.css';

function Loader() {
  return (
    <div id="spinner" />
  )
}

export default Loader;